import React from 'react';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../../components/layout';

export const fluidImg = graphql`
  fragment fluidImg on File {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const query = graphql`
  query {
    # collab: file(relative)
    sdc: file(
      relativePath: { eq: "projects/sdc-system/student-dashboard.png" }
    ) {
      ...fluidImg
    }
  }
`;

const Page = (props) => (
  <Layout>
    <Helmet>
      <body page-name="projects" />
    </Helmet>
    <main>
      <h1>projects</h1>
      <section>
        {/* <h2>Helping Hands Training Portal</h2>
        <p>
          A tailor-made online application for the NC Collaborative and NC
          Families United. Provides a custom venue for creating and distributing
          training content and assessments pursuant to the Collaborative’s
          strategic goal to “increase awareness and understanding of System of
          Care impact … and provide educational programs to enhance Systems of
          Care.”
        </p>
        <p style={{ textAlign: 'right' }}>
          <em>more info soon!</em>
        </p>

        <hr /> */}

        <h2>
          <Link to="/projects/sdc-system">
            SDC Team & Learning Management System
          </Link>
        </h2>
        <p>
          A web application used by teaching staff and students at the{' '}
          <a href="https://sdc.csc.ncsu.edu" rel="noopener">
            Senior Design Center
          </a>{' '}
          (NCSU Dept. Computer Science) to manage day-to-day academic and
          administrative processes.
        </p>

        <p>Includes functionalities such as:</p>
        <ul>
          <li>Student registration & project bids</li>
          <li>Team and advisor assignments</li>
          <li>GitHub repository provisioning</li>
          <li>Team deliverables submissions</li>
          <li>Incremental & staged advisor feedback process for reports</li>
          <li>Individual student work logs & reflections</li>
          <li>Peer evaluations</li>
        </ul>
        <Img
          alt="screenshot of student-facing web dashboard"
          className="project-screenshot with-shadow"
          style={{ marginTop: '1em' }}
          fluid={props.data.sdc.childImageSharp.fluid}
        />
        <p style={{ textAlign: 'right' }}>
          <Link to="/projects/sdc-system">read more →</Link>
        </p>
      </section>
    </main>
  </Layout>
);

export default Page;
